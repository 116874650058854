import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import { ChangeEvent, FC, useState } from 'react';
import { IImage, useInViewScroll, useSanitizedTranslation } from '../';
import { ToggleButton, Highlighted, SearchBar } from './';

type DropDownBlockProps = {
  modal?: boolean;
  items: Record<string, any>[];
  ItemComponent: (props: any) => JSX.Element;
  heading?: string;
  searchPlaceholder: string;
  backgroundImage?: IImage;
  backgroundColor?: string;
};

export const DropDownBlock: FC<DropDownBlockProps> = ({ modal, items, ItemComponent, heading, searchPlaceholder }) => {
  const { t } = useSanitizedTranslation();
  const { ref, progress: y } = useInViewScroll([0, 200], ['start 100vh', 'end 0vh']);

  const [openItems, setOpenItems] = useState<number[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    const filteredQuestions = items
      .map((item, index) => ({ ...item, index }))
      .filter(item =>
        Object.values(item).some(
          field =>
            (typeof field === 'string' && _.lowerCase(field).includes(_.lowerCase(e.target.value))) ||
            (Array.isArray(field) && field.some(subfield => _.lowerCase(subfield.toString()).includes(_.lowerCase(e.target.value)))),
        ),
      );
    setOpenItems(e.target.value === '' || e.target.value.length <= 2 ? [] : filteredQuestions.map(item => item.index));
  };

  return (
    <>
      <div className={`wrapper ${modal && 'px-0'} relative flex w-full flex-col items-center transition-all`}>
        <div
          className={`form.modal grow-1 z-20 flex w-full flex-col items-center
         justify-center rounded-md bg-white  ${!modal && 'shadow-md xl:py-12'}  transition-all`}
        >
          {heading && <h2 className="type-heading pt-12 text-center" data-i18n={heading} dangerouslySetInnerHTML={{ __html: t(heading) }} />}
          <SearchBar value={searchValue} handleChange={handleSearchChange} placeHolder={searchPlaceholder} />
          <motion.ul className="flex w-[88%] flex-col items-center justify-center overflow-hidden pt-12 max-sm:pb-16 sm:py-20">
            {items.map((item, index) => {
              const isOpen = openItems.includes(index);
              return (
                <li key={index} className="flex w-full flex-col  border-b-2 py-2">
                  <div className="flex w-full flex-row items-center">
                    <ToggleButton
                      isOpen={isOpen}
                      setIsOpen={() => setOpenItems([...openItems, index])}
                      setIsClosed={() => setOpenItems(openItems.filter(v => v !== index))}
                      styleOverrides={{ tailwind: ' leading-[0] mr-4 h-8 w-8' }}
                    />
                    <h3 className="type-body-bold text-left" data-i18n={item.heading}>
                      <Highlighted text={t(item.heading)} highlight={searchValue} />
                    </h3>
                  </div>
                  <motion.div
                    className="overflow-hidden"
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: isOpen ? 'auto' : 0, opacity: 1 }}
                    transition={{ duration: 0.2 }}
                  >
                    <AnimatePresence>{isOpen && <ItemComponent {...item} searchValue={searchValue} />}</AnimatePresence>
                  </motion.div>
                </li>
              );
            })}
          </motion.ul>
        </div>
      </div>
    </>
  );
};
