import { motion } from 'framer-motion';
import { useContext } from 'react';
import { PageContext } from '../contexts';
import { useInViewScroll, useSanitizedTranslation } from '../hooks';
import { CTA, IImage } from '../types';
import { isEnterprise } from '../utils';
import { IFormField, XumoForm } from '.';
import { useScrolledTheme } from 'ui';

export interface LeadGenFormProps {
  key?: string;
  heading: string;
  body: string;
  formFields: IFormField[];
  CTA: CTA;
  backgroundColor?: string;
  bottomBackgroundColor?: string;
  backgroundImage?: IImage;
  headingOne?: boolean;
}

const squiggleVariant = {
  hidden: {
    opacity: 0,
    scale: 1.05,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      staggerChildren: 0.055,
      type: 'spring',
      stiffness: 90,
      damping: 23,
      restDelta: 0.001,
    },
  },
};

export function LeadGenForm({ CTA, heading, body, formFields, backgroundColor, bottomBackgroundColor, backgroundImage, headingOne }: LeadGenFormProps) {
  const { ref, progress: y } = useInViewScroll([200, 0], ['start 100vh', 'end 0vh']);
  const { pageCtx } = useContext(PageContext);
  const { t } = useSanitizedTranslation();

  const { host } = pageCtx;

  const HeadingComponent = headingOne ? motion.h1 : motion.h2;

  const { componentInView } = useScrolledTheme({
    ref,
    background: backgroundColor,
    // TODO: add accent to directus
    accent: '#000000',
    text: '#000000',
  });

  return (
    <>
      <div ref={ref} className="relative z-40 mt-16 w-full">
        <section id="contact" className="relative w-full" data-testid={'lead-form-section'}>
          <div className="relative mb-[-60px] flex w-full flex-col items-center">
            <div
              className="form.modal wrapper grow-1 z-20 flex max-w-[88vw] flex-col items-center
        justify-center rounded-md bg-white shadow-md max-md:mx-6 sm:mx-0 sm:w-3/4 sm:px-12 md:w-[84%] xl:w-[64%] xl:py-12"
            >
              <HeadingComponent
                className="weight-700 mb-4 mt-12 px-12 text-center font-WorkSans text-xl leading-[36px] tracking-[-0.47px] md:mb-0 md:px-0 lg:text-4xl"
                data-i18n={heading}
                dangerouslySetInnerHTML={{ __html: t(heading) }}
              />
              <div className="weight-300 type-body p-6 text-center lg:w-2/3 allow-html" data-i18n dangerouslySetInnerHTML={{ __html: body }} />
              <XumoForm
                formFields={formFields}
                CTA={CTA}
                formAction={{ submissionEndpoint: '/api/contact', successMessage: '' }}
                styleOverrides={{
                  CTA: {
                    general: `text-white border-transparent py-2 px-12`,
                    disabledColor: 'bg-xumoSmoke',
                    enabledColor: 'bg-xumoTeal',
                  },
                  formField: `${isEnterprise(host) ? 'focus:border-b-xumoAegean caret-xumoAegean' : 'focus:border-b-xumoBerry caret-xumoBerry'} mb-8 sm:mb-0`,
                  form: 'w-full justify-center items-center px-8 pb-8 sm:px-16 sm:pb-16 sm:pt-8',
                }}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
