'use client';

/* eslint-disable max-len */
import { motion, useSpring, useTransform } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useInViewScroll, useSanitizedTranslation, useWindowSize } from '../hooks';
import { makeVariants } from '../utils/makeVariants';
import { MotionImage } from './AnimatedImage';
import { CopyProps, CopySection } from './CopySection';
import { useScrolledTheme, GlobeBlock, StatsBlock, TechStackBlock } from 'ui';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Image from 'next/image';

import { CTA, Colors, IImage } from '../types';

export type AdvertisingStatsProps = {
  copyProps: CopyProps;
  backgroundColor: string;
  imageLayout: { top: Layout; bottom: Layout };
  topSwirl: IImage;
  bottomSwirl?: IImage;
  deviceImages?: IImage[];
  devicePlacement?: 'left-overlap' | 'left' | 'right-overlap' | 'right';
  ctas: CTA[];
  headingOne?: boolean;
  fineprint?: string;
  imageFineprint?: string;
};

type Layout = 'full-screen' | 'right' | 'right-wide';

const squiggleVariant = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.055,
      type: 'spring',
      stiffness: 90,
      damping: 23,
    },
  },
};

// TODO: Add accents to TvSplash
const colorFallback = {
  '/shop': '#396059',
  '/shop/xumo-tv': '#0029FF',
  '/shop/xumo-stream-box': '#0029FF',
  '/shop/streaming': '#0029FF',
};

const retailFg  = '/static/images/blue-ribbon.png'
const retailSwirl = '/static/images/blue-ribbon.png'


const streamingFg = '/static/images/xumo-stream-box-product.png'
const streamingSwirl = '/static/images/blue-ribbon.png'

// TODO: add to Directus
const video = 'https://xumo-marketing.directus.app/assets/8630252f-4f56-4c2f-b922-342de8c54a91';

const DescriptiveButton: React.FC<{ heading: string; description: string; href: string }> = ({ heading, description, href }) => {
  const { t } = useSanitizedTranslation();
  const chevronVariant = {
    initial: { x: 0, transition: { type: 'spring', damping: 65, stiffness: 900, mass: 1 } },
    animate: { x: 10, transition: { type: 'spring', damping: 65, stiffness: 900, mass: 1 } },
  };

  const buttonVariant = {
    initial: { background: '#d8e7f300', transition: { type: 'spring', damping: 65, stiffness: 900, mass: 1 } },
    animate: { background: '#d8e7f3ff', transition: { type: 'spring', damping: 65, stiffness: 900, mass: 1 } },
  };

  return (
    <motion.div variants={buttonVariant} initial="initial" whileHover="animate" className="-ml-3 mb-1 cursor-pointer overflow-hidden rounded-lg p-3 text-black">
      <Link href={href} passHref className="flex flex-col">
        <span className="type-subheading text-black">
          <span data-i18n>{t(heading)}</span>
          <motion.svg
            variants={chevronVariant}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 622.6 657.02"
            className="ml-2 inline-block object-contain"
            style={{ width: 20, height: 20 }}
          >
            <path fill={'#06B2B6'} d="M171.96,0l246.9,328.36-247.44,328.66h193.37l247.21-328.36L365.25,.31l-.23-.31H171.96Z" />
          </motion.svg>
        </span>
        <span data-i18n className="type-body text-base text-xumoOnyx">
          {t(description)}
        </span>
      </Link>
    </motion.div>
  );
};

export const AdvertisingStats: React.FC<AdvertisingStatsProps> = ({
  copyProps,
  backgroundColor,
  topSwirl,
  bottomSwirl,
  deviceImages: imgs,
  devicePlacement,
  imageLayout,
  headingOne,
  fineprint,
  imageFineprint,
  ctas,
}) => {
  const { width } = useWindowSize();
  const { ref, progress: y } = useInViewScroll([0, 100], ['start 100vh', 'end 0vh']);
  const amt = useSpring(y, { stiffness: 400, damping: 90 });
  const containerY = useTransform(amt, [0, 1], ['-15vh', '0']);

  const router = useRouter();

  const variants = makeVariants.slideIn();
  const imgVariants = {
    initial: {
      opacity: 0,
      rotateY: '-50deg',
      x: 30, //devicePlacement === 'right-overlap' ? 30 : devicePlacement === 'left-overlap' ? -30 : 0,
    },
    animate: (i: number) => ({
      opacity: 1,
      rotateY: '-10deg',
      x: 0,
      transition: {
        type: 'spring',
        delay: 0.4 * i,
      },
    }),
  };

  const { componentInView, colors } = useScrolledTheme({
    ref,
    background: '#efefef',
    accent: '#2AAC00',
    text: backgroundColor === '#155772' ? '#ffffff' : '#000000',
  });

  const videoRef = useRef();

  const [videoShown, showVideo] = useState(false);

  useEffect(() => {
    if (videoShown && videoRef.current) {
      (videoRef.current as any).play();
    }
  }, [videoShown]);

  const tvHero = router.asPath.indexOf('/products/xumo-tv') === 0 && headingOne;
  const enterprise = router.asPath.indexOf('/enterprise') === 0 && router.asPath.indexOf('/enterprise/case') !== 0;
  const enterpriseHero = enterprise && headingOne;

  const isAdvertising = router.asPath.indexOf('/advertising') === 0
  const adStyles = isAdvertising
   ? {style: {background: 'white', borderRadius: 15}}
  : {}

  // right: A8306C
  
  return (
    <>
      <section ref={ref} className={`relative -mx-8 z-10 flex w-full flex-col pb-[50px] max-md:mt-[50px] md:flex-row`} data-id={'tv-splash-block'}>
        <motion.div
          variants={variants}
          initial="initial"
          whileInView={'animate'}
          viewport={{ once: true }}
          className={`wrapper space-y-4 relative`}
          style={{ color: colors.text }}
        >
          <div className="bg-xumoBerry relative overflow-hidden w-full">
            <img src="/static/images/gradient-1.svg" className="absolute object-cover h-full w-full left-0 right-0 bottom-0 top-0 z-0" alt="" />
            <div className="relative z-10 flex flex-col lg:flex-row p-8">
              <div className="text-center flex flex-col items-center justify-center p-4 lg:pr-16 lg:border-r lg:border-white">
                <span className="flex font-WorkSans text-7xl weight-900 text-white leading-[90%]" style={{filter: 'drop-shadow(5px 5px 0px #BB2E76)'}}>Over 10,000</span>
                <span className="flex type-body text-white pt-4">ad supported titles</span>
              </div>
              <div className="flex flex-col p-4 lg:pl-16 text-left">
                <span className="flex type-heading text-3xl text-white mb-4">Investing in more content fans love</span>
                <span className="flex type-body text-white">We are taking center stage by continuously adding new content and channels on Xumo from content partners like NBCUniversal, BBC, and Magnolia. We continue to invest in more end-points to give our advertisers more reach, like the first-ever pay TV integration with Xfinity. Our audience enjoys 10,000+ ad support titles, giving our advertisers maximum impact to reach potential customers on CTV, AVOD, and FAST.</span>
              </div>
            </div>
          </div>

          <div className="">
            <div className="relative h-[400px] overflow-hidden">
              <MotionImage
                className={`w-full object-cover max-sm:h-full`}
                sizes="(max-width: 768px) 100vw, (max-width: 1600px) 60vw, 50vw"
                quality={80}
                src={'/static/images/ad-stats.png'}
                alt={''}
                priority
                fill
            />
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-4 relative z-10">
            <div className="relative flex-col p-8 flex overflow-hidden basis-1/2 items-center">
              <img src="/static/images/gradient-1.svg" className="absolute object-cover h-full w-full left-0 right-0 bottom-0 top-0 z-0" alt="" />
              <div className="relative z-10 mb-4">
                <span className="flex type-heading text-3xl text-white mb-4">Xumo delivers an<br/>incremental audience</span>
              </div>
              <div className="flex relative z-10">
                <span className="flex font-WorkSans text-5xl weight-900 text-white pr-4 leading-[90%]" style={{filter: 'drop-shadow(5px 5px 0px #BB2E76)'}}>95%</span>
                <span className="text-white text-left">of HHs reached by Xumo were incremental to linear TV portion of campaigns*</span>
              </div>
              <div className="flex relative z-10 w-full text-left mt-2 mb-4">
               <span className="inline-block bg-clip-padding bg-white w-full overflow-hidden h-[30px]">
                <motion.span 
                  initial={{scaleX: 0}}
                  whileInView={{scaleX: 1}}
                  transition={{ delay: 0.05, duration: 0.75, deltaRest: 0.0001, ease: [0, 0.45, 0.3, 1] }}
                  viewport={{once: true}}
                  className="inline-block bg-[#BB2E76] w-[95%] h-full origin-left" />
               </span>
              </div>


              <div className="flex relative z-10">
                <span className="flex font-WorkSans text-5xl weight-900 text-white pr-4 leading-[90%]" style={{filter: 'drop-shadow(5px 5px 0px #BB2E76)'}}>87%</span>
                <span className="text-white text-left">of HHs reached by Xumo cannot be reached through other FAST services*</span>
              </div>
              <div className="flex relative z-10 w-full text-left mt-2">
               <span className="inline-block bg-clip-padding bg-white w-full overflow-hidden h-[30px]">
                <motion.span 
                  initial={{scaleX: 0}}
                  whileInView={{scaleX: 1}}
                  viewport={{once: true}}
                  transition={{ delay: 0.1, duration: 1, deltaRest: 0.00001, ease: [0, 0.45, 0.3, 1] }}
                  className="inline-block bg-[#BB2E76] w-[87%] h-full origin-left" />
               </span>
              </div>
            </div>
            <div className="relative overflow-hidden flex flex-col justify-between basis-1/2">
              <img src="/static/images/gradient-1.svg" className="-scale-x-100 object-cover absolute h-full w-full left-0 right-0 bottom-0 top-0 z-0" alt="" />
              <div className="text-center grow flex flex-col items-center justify-center p-8 z-10 relative">
                <span className="flex font-WorkSans text-9xl weight-900 text-white leading-[90%]" style={{filter: 'drop-shadow(5px 5px 0px #BB2E76)'}}>95%</span>
                <span className="flex type-body text-white pt-4">Addressable backed by MVPD data enablement can yield a 95% initial match rate*​</span>
              </div>
              <div className="type-body text-white p-4 text-[10px] relative z-10">
               *Source: Analysis based on Comcast Advertising data. Study source -CIMM/GoAddressable  Guidelines for Planning & Buying Addressable TV Advertising’ February 2024. WEB. 28 Feb 2024.​
              </div>
            </div>
          </div>
        </motion.div>
      </section>
    </>
  );
};
