import { motion, TargetAndTransition } from 'framer-motion';
import React, { useContext, useEffect, useMemo } from 'react';
import { CTA, StyleOverride, SplitH2, SplitH3, SplitH4, Alignment } from '..';
import { PageContext } from '../contexts';
import { useContent } from '../hooks';
import { isEnterpriseOrShopper } from '../utils';
import { makeVariants } from '../utils/makeVariants';
import { CTAList, ColumnItems, SplitH1 } from './';

export type CopyProps = {
  isColumn?: boolean;
  withDivider?: boolean;
  heading?: string;
  subHeading?: string;
  headingOne?: boolean;
  body?: string;
  ctas?: Array<CTA>;
  columnItems?: CopyProps[];
  config: CopyConfig;
  inheritColors?: boolean;
  showRatings?: boolean;
};

export type CopyConfig = {
  isColumn: boolean;
  largeHeading: boolean;

  withDivider: boolean;
  backgroundColor?: string;
  accent?: string;
  variantOverride?: Record<string, TargetAndTransition>;
  styleOverride?: Partial<StyleOverride>;
  alignment?: Alignment;
};

interface ContentKeys extends Record<string, string> {
  heading: string;
  subHeading: string;
  body: string;
}

export const CopySection = ({
  heading,
  subHeading,
  body,
  ctas,
  columnItems,
  headingOne,
  inheritColors,
  config = {
    isColumn: false,
    largeHeading: false,
    withDivider: false,
    backgroundColor: undefined,
    accent: undefined,
    variantOverride: undefined,
    styleOverride: undefined,
    alignment: Alignment.Left,
  },
}: // eslint-disable-next-line sonarjs/cognitive-complexity
CopyProps) => {
  const content = useContent<ContentKeys>({ heading: heading ?? '', subHeading: subHeading ?? '', body: body ?? '' }, [heading, subHeading, body]);
  const variants = makeVariants.slideIn((config ?? {})?.variantOverride ? config?.variantOverride : {});

  const { pageCtx } = useContext(PageContext);
  const host = pageCtx?.host;
  const isCorporate = !isEnterpriseOrShopper(host);

  const { alignment, isColumn, largeHeading, withDivider, accent: primary, backgroundColor, styleOverride, variantOverride } = config ?? {};
  const Heading = isColumn ? SplitH3 : headingOne ? SplitH1 : SplitH2;
  const SubHeading = isColumn ? SplitH4 : SplitH2;

  return (
    <motion.div
      variants={variants}
      viewport={{ once: true }}
      className={`flex flex-col justify-center font-WorkSans first:border-l-0 max-sm:border-0
 ${isColumn ? ' w-1/2 py-4' : 'mx-auto w-full'} 
      ${alignment === Alignment.Left ? 'items-start' : alignment === Alignment.Center ? 'items-center' : 'items-end'} `}
      style={{
        borderColor: isColumn && withDivider ? (primary ? primary : isCorporate ? '#BB2E76' : '') : undefined,
        borderLeftWidth: withDivider ? '2px' : undefined,
        backgroundColor,
        color: inheritColors ? 'inherit' : 'initial',
      }}
    >
      {heading && (
        <Heading
          content={heading}
          variants={variants}
          className={`flex flex-col transition-all w-full ${
            isColumn ? 'type-subheading text-center' : headingOne ? 'type-title text-left' : 'type-heading text-left'
          }`}
          style={{ color: isColumn ? primary : undefined }}
        />
      )}

      {subHeading && <SubHeading content={subHeading} variants={variants} className={`flex flex-col text-left text-[32px]`} />}
      {columnItems && <ColumnItems columnItems={columnItems} withDivider={withDivider} primary={primary} />}
      {body && (
        <motion.div
          variants={variants}
          className={`${isColumn ? 'my-auto' : ''} text-body py-4 text-left transition-all text-base md:text-base lg:text-lg`}
          data-i18n={body}
          dangerouslySetInnerHTML={content.body}
        />
      )}
      {!!ctas?.length && <CTAList ctas={ctas} alignment={alignment} />}
    </motion.div>
  );
};
