'use client';

/* eslint-disable max-len */
import { motion, useSpring, useTransform, AnimatePresence } from 'framer-motion';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useInViewScroll, useSanitizedTranslation, useWindowSize } from '../hooks';
import { makeVariants } from '../utils/makeVariants';
import { MotionImage } from './AnimatedImage';
import { CopyProps, CopySection } from './CopySection';
import { useScrolledTheme, Button } from 'ui';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Image from 'next/image';

import { CTA, Colors, IImage } from '../types';

export type TvSplashBlockProps = {
  copyProps: CopyProps;
  backgroundColor: string;
  imageLayout: { top: Layout; bottom: Layout };
  topSwirl: IImage;
  bottomSwirl?: IImage;
  deviceImages?: IImage[];
  devicePlacement?: 'left-overlap' | 'left' | 'right-overlap' | 'right';
  ctas: CTA[];
  headingOne?: boolean;
  fineprint?: string;
};

type Layout = 'full-screen' | 'right' | 'right-wide';

const squiggleVariant = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.055,
      type: 'spring',
      stiffness: 90,
      damping: 23,
    },
  },
};

// TODO: Add accents to TvSplash
const colorFallback = {
  '/shop': '#396059',
  '/shop/xumo-tv': '#0029FF',
  '/shop/xumo-stream-box': '#0029FF',
  '/shop/streaming': '#0029FF',
};

const retailFg  = '/static/images/blue-pretzel.png'
const retailSwirl = '/static/images/blue-pretzel.png'

const streamingFg = '/static/images/xumo-stream-box-product.png'
const streamingSwirl = '/static/images/blue-ribbon.png'

// TODO: add to Directus
const video = 'https://xumo-marketing.directus.app/assets/8630252f-4f56-4c2f-b922-342de8c54a91';


export const EnterpriseHero: React.FC<TvSplashBlockProps> = ({
  copyProps = {
    heading: 'Stream big.',
    body: '<strong>Xumo has been innovating and pushing the streaming landscape forward since 2011.</strong> We drive connections that enhance entertainment experiences for both your businesses and your audiences.'
  },
  backgroundColor = '#E0E7ED',
  topSwirl,
  bottomSwirl,
  deviceImages = [
    {src: '/static/images/enterprise-xumo-tv.png'},
    {src: '/static/images/enterprise-bubbles.png'},
    {src: '/static/images/enterprise-advertisers.png'},
    {src: streamingFg},
    {src: '/static/images/enterprise-xumo-tv.png'},
  ],
  devicePlacement,
  imageLayout,
  headingOne = true,
  fineprint,
  imageFineprint,
  ctas
}) => {
  const { width } = useWindowSize();


  const isMobile = width <= 767;
 
  const { ref, progress: y } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
  const amt = useSpring(y, { stiffness: 400, damping: 90 });
  const containerY = useTransform(amt, [0, 1], ['0', '-50vh']);
  const opacity = useTransform(amt, [0.25, 1], [1, 0]);

  const [ [id, direction, random], setPage ] = useState([-1,0,Math.random()])

  const setSlide = useCallback(nid => {
    const newDirection = nid > id
      ? 1
      : -1
      
    const newId = (nid + deviceImages.length) % deviceImages.length
    setPage([newId, newDirection, Math.random()])
  }, [id])


  const videoRef = useRef<HTMLVideoElement | null>(null);

  const [isPaused, setIsPaused] = useState(false);

    const handleVideo = useCallback(() => {
    setIsPaused(!isPaused);
    if (videoRef.current) {
      if (!isPaused) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
    }
  }, [isPaused]);

  const router = useRouter();

  const flipped = copyProps.heading.indexOf('Retail') >= 0
  const hiddenButton = copyProps.heading.indexOf('hardware at scale') >= 0

  const fallbackSwirl = retailSwirl

  const fallbackColor = colorFallback[router.asPath as keyof typeof colorFallback];

  const variants = makeVariants.slideIn();
  const imgVariants = {
    initial: {
      opacity: 0,
      x: 30, //devicePlacement === 'right-overlap' ? 30 : devicePlacement === 'left-overlap' ? -30 : 0,
    },
    animate: (i: number) => ({
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        delay: 0.4 * i,
      },
    }),
  };

  const videoVariants = {
    initial: {
      opacity: 0,
      rotateY: '-60deg',
      x: 30, //devicePlacement === 'right-overlap' ? 30 : devicePlacement === 'left-overlap' ? -30 : 0,
    },
    animate: (i: number) => ({
      opacity: 1,
      rotateY: '-10deg',
      x: 0,
      transition: {
        type: 'spring',
        stiffness: 80,
        damping: 30,
      },
    }),
  };

  const { componentInView, colors } = useScrolledTheme({
    ref,
    background: '#155772',
    accent: fallbackColor || '#2AAC00',
    text: '#ffffff'
  });


  const tvHero = router.asPath.indexOf('/products/xumo-tv') === 0 && headingOne;
  const enterprise = router.asPath.indexOf('/enterprise') === 0 && router.asPath.indexOf('/enterprise/case') !== 0;
  const enterpriseHero = enterprise && headingOne;

// 
  return (
    <>
      <div className="relative w-full">
      {!isMobile ? (
        <button className={`absolute right-[32px] top-[45%] z-30 cursor-pointer`} onClick={handleVideo}>
          {!isPaused ? (
            <Image alt="pause" src="/pause.png" width={40} height={40} />
          ) : (
            <Image style={{ opacity: 0.4 }} alt="image" src="/play-circle.png" width={40} height={40} />
          )}
        </button>
      ) : null}
      
      <div className="pointer-events-none fixed bottom-0 left-0 right-0 top-0 h-[115vh] overflow-hidden">
        <motion.div style={{ y: containerY, opacity }} className="absolute left-0 top-0 w-full h-full">
          <AnimatePresence>
            {fallbackSwirl && componentInView && (
              <motion.div
                key="squiggle"
                initial="hidden"
                animate="show"
                exit="hidden"
                className="absolute left-0 top-0 w-full h-full max-md:opacity-10">
                <MotionImage
                  variants={squiggleVariant}
                  src={fallbackSwirl}
                  alt=""
                  quality={85}
                  sizes="(max-width: 1600px) 100vw, 75vw"
                  fill
                  className={`relative left-[-10%] h-full  w-full object-cover object-left md:left-[50%] lg:w-auto`}
                  style={{
                    scaleX: flipped ? -1 : 1,
                    transformOrigin: 'left'
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>

      <section ref={ref} className={`relative flex w-full flex-col pb-[50px] max-md:mt-[100px] z-10`} data-id={'tv-splash-block'}>
        <motion.div
          variants={variants}
          initial="initial"
          whileInView={'animate'}
          viewport={{ once: true }}
          className={`wrapper relative z-[2] flex flex-col items-center justify-center ${flipped ? 'xl:flex-row-reverse' : 'xl:flex-row'}`}
          style={{ 
            color: colors.text }}
        >
          <div className="w-full text-left xl:w-2/5 lg:pr-8">
            <CopySection headingOne={headingOne && !tvHero} showRatings={tvHero} inheritColors {...copyProps} config={{ ...copyProps.config }} />

          <motion.div
            style={{ paddingTop: isMobile ? '10px' : '20px' }}
            className={`flex flex-col max-md:space-y-4 md:flex-row justify-start items-start md:items-center mb-8`}
          >
            <Button href="/enterprise/contact" label={'Become a partner'} buttonType="link-primary" styleOverrides={`bg-white text-xumoAegean sm:inline-block mr-8`} />
            <Link href="#sizzle" legacyBehavior passHref>
                <motion.a
                  whileHover="grow"
                  className={`inline-flex flex-row items-center`}
                  style={{ }}
                >
                  <motion.div variants={{ grow: { scale: 1.1 } }} className="relative z-10 h-[40px] w-[40px]">
                    <Image alt="Play button" src="/static/icons/play_glow.svg" width={40} height={40} />
                  </motion.div>
                  <motion.span variants={{ grow: { opacity: 0.7 } }} className="weight-600 ml-2 text-left text-white">
                    Watch the full<br/>Xumo Enterprise reel
                  </motion.span>
                </motion.a>
              </Link>
            </motion.div>
            {fineprint && <div className="type-body mt-8 text-[12px]" data-i18n={fineprint} dangerouslySetInnerHTML={{ __html: fineprint }} />}
          </div>
          <svg className="opacity-0 absolute">
            <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox"><path d="M1,0.667 L0.998,0.335,0.89,0.333,0.888,0.001,0.225,0,0.224,0.335,0.002,0.335,0,0.666,0.225,0.667,0.224,0.999,0.89,1,0.888,0.667,1,0.667"></path></clipPath>
          </svg>

          <div className="lg:flex relative lg:pl-32 mt-12 max-md:mb-12 min-h-[300px] w-full items-center lg:mt-0 lg:h-[500px] 2xl:h-[700px] xl:w-3/5">
            <motion.div className="relative w-full aspect-video drop-shadow-2xl xl:left-[-10%] xl:scale-150 xl:origin-left">
              <Image src="/static/images/tv-frame.png" style={{transform: "scale(1.003)"}} fill className="w-full h-full z-10 relative brightness-[0.2]" />

              <div 
                className="absolute top-0 left-0 w-full h-full"
                  style={{
                    clipPath: 'url(#my-clip-path)'
                  }}>
               <video
                  ref={videoRef}
                  autoPlay
                  muted
                  loop
                  playsInline
                  src="/static/videos/enterprise-opt.mp4"
                  alt=""
                  className="absolute top-0 left-0 w-full h-full"
                />
              </div>
            </motion.div>
          </div>
        </motion.div>
        <motion.div className="relative z-10 wrapper flex flex-col max-lg:mt-[30px] lg:flex-row justify-between lg:items-end text-white lg:h-[100px] 2xl:h-[120px] relative xl:top-[-50px]">
          <Link href="#content-partners" legacyBehavior passHref>
            <motion.a 
              layout 
              style={{
                color: '#fff',
                background: colors.text === '#ffffff' ? '#15577277' : '#155772ff'
              }} 
              className="border-l border-current p-4 max-lg:mb-4 lg:w-[185px] backdrop-blur-lg text-left" 
              onMouseOver={() => setSlide(0)}>
              <motion.span layout className="weight-500 uppercase block">Content&nbsp;owners</motion.span>
              {(id === 0 || isMobile) && <motion.span layout className="mt-1 lg:mt-4 inline-block"
                animate={{opacity: 1, y: 0}} initial={{opacity: 0, y: -25 }}>
                Build your own FAST channels
              </motion.span>}
            </motion.a>
          </Link>
          <Link href="#syndication" legacyBehavior passHref>
            <motion.a 
              layout 
              style={{
                color: '#fff',
                background: colors.text === '#ffffff' ? '#15577277' : '#155772ff'
              }} 
              className="border-l border-current p-4 max-lg:mb-4 lg:w-[185px] backdrop-blur-lg text-left" 
              onMouseOver={() => setSlide(1)}>
              <motion.span layout className="weight-500 uppercase block">Distributors</motion.span>
              {(id === 1 || isMobile) && <motion.span layout className="mt-1 lg:mt-4 inline-block"
                animate={{opacity: 1, y: 0}} initial={{opacity: 0, y: -25 }}>
                Elevate your streaming channel lineup
              </motion.span>}
            </motion.a>
          </Link>
          <Link href="#advertisers" legacyBehavior passHref>
            <motion.a 
              layout 
              style={{
                color: '#fff',
                background: colors.text === '#ffffff' ? '#15577277' : '#155772ff'
              }} 
              className="border-l border-current p-4 max-lg:mb-4 lg:w-[185px] backdrop-blur-lg text-left" 
              onMouseOver={() => setSlide(2)}>
              <motion.span layout className="weight-500 uppercase block">Advertisers</motion.span>
              {(id === 2 || isMobile) && <motion.span layout className="mt-1 lg:mt-4 inline-block"
                animate={{opacity: 1, y: 0}} initial={{opacity: 0, y: -25 }}>
                Reach new and incremental audiences at scale
              </motion.span>}
            </motion.a>
          </Link>
          <Link href="#operators" legacyBehavior passHref>
            <motion.a 
              layout 
              style={{
                color: '#fff',
                background: colors.text === '#ffffff' ? '#15577277' : '#155772ff'
              }} 
              className="border-l border-current p-4 max-lg:mb-4 lg:w-[185px] backdrop-blur-lg text-left" 
              onMouseOver={() => setSlide(3)}>
              <motion.span layout className="weight-500 uppercase block">Operators</motion.span>
              {(id === 3 || isMobile) && <motion.span layout className="mt-1 lg:mt-4 inline-block"
                animate={{opacity: 1, y: 0}} initial={{opacity: 0, y: -25 }}>
                Complete your video offering with turn-key Xumo set-top box technology
              </motion.span>}
            </motion.a> 
          </Link>
          <Link href="#oem" legacyBehavior passHref>
            <motion.a 
              layout 
              style={{
                color: '#fff',
                background: colors.text === '#ffffff' ? '#15577277' : '#155772ff'
              }} 
              className="border-l border-current p-4 max-lg:mb-4 lg:w-[185px] backdrop-blur-lg text-left" 
              onMouseOver={() => setSlide(4)}>
              <motion.span layout className="weight-500 uppercase block">OEMs</motion.span>
              {(id === 4 || isMobile) && <motion.span layout className="mt-1 lg:mt-4 inline-block"
                animate={{opacity: 1, y: 0}} initial={{opacity: 0, y: -25 }}>
                Power your hardware with our retail-ready operating system
              </motion.span>}
            </motion.a>
          </Link>
        </motion.div>
      </section>
    </div>
    </>
  );
};
