import { motion } from 'framer-motion';
import { LinkProps } from 'next/link';
import { useEffect, useContext } from 'react';
import isBrowser from '../utils/isBrowser';
import { NavigationHeaderLinkItem } from './NavigationHeaderLinkItem';
import { IImage, PageContext } from 'ui';

export type NavigationHeaderLinksProps = {
  links: HeaderLink[];
  secondaryLinks: HeaderLink[];
  isMobile?: boolean;
  styleOverrides: {
    button?: string;
    list?: string;
    links?: string;
  };
  isNavOpen: boolean;
  setIsNavOpen: (open: boolean) => void;
};

export interface HeaderLinkItem {
  label: string;
  href: string;
}
export interface HeaderLinkSection {
  title: string;
  links: HeaderLinkItem[];
}

export interface HeaderLinkFeatured {
  title: string;
  image: IImage;
  link: HeaderLinkItem;
}

export interface HeaderLink {
  href: LinkProps['href'] | string;
  label: string;
  preview?: boolean;
  subnav?: {
    sections?: HeaderLinkSection[];
    featured?: HeaderLinkFeatured;
    cta?: boolean;
  };
}

const variants = {
  initial: {
    y: -20,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { staggerChildren: 0.075, ease: 'easeInOut', duration: 0.01 },
  },
  exit: {
    y: 20,
    opacity: 0,
  },
};

export const NavigationHeaderLinkList = ({ links, secondaryLinks, styleOverrides, isNavOpen, setIsNavOpen, isMobile = false }: NavigationHeaderLinksProps) => {
  useEffect(() => {
    if (isBrowser) {
      if (isMobile && isNavOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    }
  }, [isNavOpen, isMobile]);

  const { theme } = useContext(PageContext);
  return (
    <>
      <motion.div
        className={`${
          isMobile && isNavOpen ? 'fixed h-[100vh] w-full overflow-y-scroll pb-[150px]' : 'max-lg:pointer-events-none max-lg:hidden'
        } left-0 top-[65px] grow justify-start text-inherit max-lg:w-full lg:top-[100%] lg:flex lg:items-center lg:text-center`}
        role="navigation"
      >
        <div className="max-lg:wrapper flex w-full max-lg:flex-col">
          <motion.div className={`flex grow max-lg:flex-col`}>
            {links.map((link, index) => (
              <NavigationHeaderLinkItem
                link={link}
                index={index}
                isMobile={isMobile}
                isNavOpen={isNavOpen}
                setIsNavOpen={setIsNavOpen}
                styleOverrides={styleOverrides.links}
                key={link.label + index}
                setIsNavOpen={setIsNavOpen}
              />
            ))}
          </motion.div>
          <motion.div className={`flex max-lg:flex-col`}>
            {secondaryLinks.map((link, index) => (
              <NavigationHeaderLinkItem
                link={link}
                index={index}
                isMobile={isMobile}
                isNavOpen={isNavOpen}
                setIsNavOpen={setIsNavOpen}
                styleOverrides={styleOverrides.links}
                key={link.label + index}
              />
            ))}
          </motion.div>
        </div>
      </motion.div>
      <motion.div className="flex justify-end max-lg:h-2 max-lg:items-center">
        {/*        <Button
          label={'Xumo Enterprise'}
          href={'/enterprise'}
          buttonType={'link-primary'}
          styleOverrides={{
            tailwind: 'max-lg:text-sm inline-block max-lg:px-2 max-lg:py-1 lg:px-4 lg:py-2 text-white',
            css: {
              backgroundColor: theme.accent,
              borderColor: theme.accent,
              borderWidth: 2,
              boxSizing: 'border-box',
            },
          }}
        />
        <Button
          label={'Account'}
          href={'https:/tv.xumo.com/account'}
          buttonType={'link-primary'}
          styleOverrides={{
            tailwind: 'ml-3 max-lg:text-sm inline-block max-lg:px-2 max-lg:py-1 lg:px-4 lg:py-2 text-white',
            css: {
              backgroundColor: theme.accent,
              borderColor: theme.accent,
              borderWidth: 2,
              boxSizing: 'border-box',
            },
          }}
        />*/}
        {/*<Button
          label={'EN'}
          buttonType={"link-primary"}
          styleOverrides={{
            tailwind: 'ml-3 max-lg:text-sm max-lg:px-2 max-lg:py-1 lg:px-4 lg:py-2',
            css: { color: theme.accent, borderColor: theme.accent, borderWidth: 2, background: 'transparent', boxSizing: 'border-box' }
          }}
        />*/}
      </motion.div>
    </>
  );
};
