/* eslint-disable max-len */
import { motion, AnimatePresence } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import Color from 'color';
import Link from 'next/link';
import { useResponsive } from '../hooks/useResponsive';
import { PageContext } from '../contexts';
import { HamburgerButton, HeaderLink, NavigationHeaderLinkList } from './';
import { useRouter } from 'next/router';

// TODO: Improve configurability
export interface NavigationHeaderProps {
  links: HeaderLink[];
  secondaryLinks: HeaderLink[];
}

const navProps: NavigationHeaderProps = {
  links: [
    {
      label: 'Xumo TV',
      href: '/products/xumo-tv',
      subnav: {
        featured: {
          title: 'All you can stream',
          image: { src: '/static/images/featured-shop.png' },
          link: {
            label: 'Simple setup. Endless entertainment. Personalized for you.',
            href: '/products/xumo-tv',
          },
        },
        cta: false,
      },
    },
    {
      label: 'Xumo Stream Box',
      href: '/products/xumo-stream-box',
      subnav: {
        featured: {
          title: 'Introducing Stream Box',
          image: { src: '/static/images/streambox-video-1.png' },
          link: {
            label: 'Bring streaming home with an operating system that’s all new and all you.',
            href: '/products/xumo-stream-box',
          },
        },
        cta: false,
      },
    },
    {
      label: 'Xumo Play',
      href: 'https://play.xumo.com',
      subnav: {
        featured: {
          title: 'Free streaming',
          image: { src: '/xumo-play.png' },
          link: {
            label: 'Your destination for free LIVE and on-demand streaming channels',
            href: 'https://play.xumo.com',
          },
        },
        cta: false,
      },
    },
    {
      label: 'About',
      href: '/company',
      subnav: {
        sections: [
          {
            title: 'Company',
            links: [
              {
                label: 'Who We Are',
                href: '/company',
              },
              {
                label: 'Careers',
                href: 'https://comcast.wd5.myworkdayjobs.com/Comcast_Careers/?q=xumo',
              },
            ],
          },
          {
            title: 'Media',
            links: [
              {
                label: 'Press',
                href: '/press',
              },
            ],
          },
        ],
        // featured: {
        //   title: 'Latest Article',
        //   image: '/featured-company.jpg',
        //   link: {
        //     label: 'Meet the new Xumo',
        //     href:

        //   },
        // },
        cta: false,
      },
    },
    {
      label: 'Account',
      href: 'https://tv.xumo.com/account',
     },
    {
      label: 'Support',
      href: 'https://tv.xumo.com/support',
      subnav: {
        sections: [
          {
            title: 'Xumo TV',
            links: [
              {
                label: 'Manage Account',
                href: 'https://tv.xumo.com/account',
              },
              {
                label: 'Support Portal',
                href: 'https://tv.xumo.com/support',
              },
            ],
          }
        ],
        cta: false,
      },
    },
  ],
  secondaryLinks: [
    {
      label: 'For Business',
      href: '/enterprise',
      subnav: {
        sections: [
          {
            title: 'For Business',
            links: [
              {
                label: 'Xumo Enterprise',
                href: '/enterprise',
              },
              {
                label: 'Xumo Advertising',
                href: '/advertising'
              },
            ],
          }
        ],
        cta: false,
      },
    },
  ],
};


export const NavigationHeader: React.FC<NavigationHeaderProps> = ({ 
  // links, 
  // secondaryLinks
}) => {
  const width = useResponsive();

  const { links, secondaryLinks } = navProps;

  const isMobile = !width || width.xs || width.sm || width.md;

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isTop, setIsTop] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      const top = window.pageYOffset || document.documentElement.scrollTop;
      setIsTop(top === 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isTop]);

  const opacity = isTop ? 0.8 : 1;

  const { theme } = useContext(PageContext);

  const logoVariants = {
    initial: {
      opacity: 0,
      x: -10,
    },
    show: {
      opacity: 1,
      x: 0,
    },
    hover: {
      scale: 1.1
    },
  };

  const backgroundColor = theme.background
    ? Color(isNavOpen ? '#ffffff' : theme.background)
        .alpha(0.8)
        .rgb()
        .string()
    : 'transparent';

  const textColor = theme.text

  const router = useRouter();
  const enterprise = router.asPath.indexOf('/enterprise') === 0;

  return (
    <>
      <motion.header className={`fixed left-0 top-0 z-50 flex w-full flex-col items-start justify-start text-white lg:pt-2`} data-testid={'header-section'}>
        <motion.div
          animate={{ opacity: isNavOpen ? 1 : 0 }}
          className="pointer-events-none fixed bottom-0 left-0 right-0 top-0 h-full w-full bg-white lg:hidden shadow-md"
        />
        <div className="lg:wrapper max-lg:w-full">
          <div
            id="header-bar"
            style={{
              backgroundColor,
              transition: 'background 0.5s',
            }}
            className={`w-full  p-4 backdrop-blur-md lg:-ml-6 lg:w-[calc(100%+3rem)] lg:rounded-lg`}
          >
            <div className="max-lg:wrapper flex min-h-[48px] w-full items-center justify-start  lg:justify-between ">
              <HamburgerButton color={textColor} isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
              <motion.div 
                animate={{
                  scale: 1
                }}
                whileHover={{ 
                  scale: 1.075
                }} 
                className="mr-4 flex justify-center">
                <Link className="inline-flex" href="/" aria-label={enterprise ? 'Xumo Enterprise' : 'Xumo'} onClick={() => setIsNavOpen(false)}>
                  <motion.svg className="mr-1" xmlns="http://www.w3.org/2000/svg" width="89" height="22" style={{ minWidth: 89 }} viewBox="0 0 350 82.22">
                      <motion.path
                        d="m308.89,0c-22.7,0-41.11,18.41-41.11,41.11s18.41,41.11,41.11,41.11,41.11-18.41,41.11-41.11S331.59,0,308.89,0Zm0,62.91c-12.04,0-21.8-9.76-21.8-21.8s9.76-21.8,21.8-21.8,21.8,9.76,21.8,21.8-9.76,21.8-21.8,21.8ZM235.38,0c-10.82,0-20.4,5.35-26.29,13.57-5.57-8.22-14.65-13.57-24.9-13.57-8.73,0-16.61,3.88-22.2,10.11l-.56-8.5h-21.07v47.94c0,8.49-7,15.37-15.63,15.37s-15.63-6.88-15.63-15.37V1.61h-21.62v47.95c0,7.13,2.15,13.72,5.79,19.09,5.57,8.22,14.65,13.57,24.9,13.57,8.73,0,16.61-3.88,22.2-10.11l.56,8.5h21.07v-47.45h0v-.49c0-8.49,7-15.37,15.63-15.37s15.63,6.88,15.63,15.37v47.94h21.62v-47.94c0-8.49,7-15.37,15.63-15.37s15.63,6.88,15.63,15.37v47.94h21.62v-47.95c0-18.04-14.5-32.66-32.4-32.66ZM62.9,80.61h24.58l-31.45-39.5-12.29,15.44,19.16,24.07Zm-19.16-54.94l12.29,15.44L87.48,1.61h-24.58l-19.16,24.07Z"
                        animate={{
                          fill: isNavOpen ? '#000000' : theme.text,
                        }}
                      />
                      <motion.polygon
                        points="43.74 25.68 24.58 1.61 0 1.61 31.45 41.11 56.03 41.11 43.74 25.68"
                        
                        fill="#bb2e76"
                      />
                      <motion.polygon
                        points="31.45 41.11 0 80.61 24.58 80.61 43.74 56.55 56.03 41.11 31.45 41.11"
                        fill="#05b2b6"
                      />
                  </motion.svg>
                  <AnimatePresence>
                    {enterprise && (
                      <motion.svg
                        key="enterprise-logo"
                        initial="initial"
                        animate="show"
                        exit="initial"
                        xmlns="http://www.w3.org/2000/svg"
                        height="22"
                        className="p-[1px]"
                        viewBox="0 0 659 89"
                      >
                        <motion.path
                          animate={{
                            fill: isNavOpen ? '#000000' : theme.text,
                          }}
                          d="M6.6 46.2h41.8V40H6.6V6.4h52.9V.3H0v87.1h61.6v-6.1h-55zM130.5 69.3l.3 10.6-6.6-11.4L78.1.3h-8.3v87.1h6.3v-69l-.4-10.6h.2l6.4 11.3 46.2 68.3h8.2V.3h-6.2zM143.7 6.4h31.8v81h6.6v-81h31.8V.3h-70.2zM227.5 46.2h41.8V40h-41.8V6.4h52.9V.3h-59.5v87.1h61.6v-6.1h-55zM343.7 42.9c5.2-4.3 7.8-10.2 7.8-17.9s-2.6-13.7-7.7-18.1A30.9 30.9 0 0 0 323 .3h-32.3v87.1h6.6V49.7h24.9l.7-.4h.2l25.2 38.1h7.8l-25.5-38.8c5.2-.9 9.6-2.8 13.1-5.7zm-46.4.6V6.4h25.6c7 0 12.4 1.6 16.2 4.8 3.7 3.2 5.6 7.8 5.6 13.8s-1.9 10.6-5.6 13.7c-3.7 3.2-9.1 4.7-16.2 4.7h-25.6zM408.8 3.4A36 36 0 0 0 393.1.3h-28.9v87.1h6.6v-36h22.3a36 36 0 0 0 15.7-3.1c4.4-2.1 7.8-5 10.2-8.8 2.4-3.8 3.6-8.4 3.6-13.7s-1.2-9.8-3.6-13.6a23.8 23.8 0 0 0-10.2-8.8zm.9 36.7c-4 3.4-9.8 5.1-17.4 5.1h-21.5V6.4h21.5c7.7 0 13.5 1.7 17.4 5.1 4 3.4 5.9 8.2 5.9 14.3s-2 10.9-5.9 14.3zM483.8 42.9c5.2-4.3 7.8-10.2 7.8-17.9s-2.6-13.7-7.7-18.1A30.9 30.9 0 0 0 463.1.3h-32.3v87.1h6.6V49.7h24.9l.7-.4h.2l25.2 38.1h7.8l-25.5-38.8c5.2-.9 9.6-2.8 13.1-5.7zm-46.4.6V6.4H463c7 0 12.4 1.6 16.2 4.8 3.7 3.2 5.6 7.8 5.6 13.8s-1.9 10.6-5.6 13.7c-3.7 3.2-9.1 4.7-16.2 4.7h-25.6zM504.3.3h6.6v87.1h-6.6zM565.9 43.1l-17-3.7c-7.1-1.6-12-3.7-14.6-6.4-2.6-2.7-3.9-6-3.9-10 0-3.3.9-6.4 2.8-9 1.8-2.7 4.6-4.8 8.2-6.5 3.6-1.6 8-2.4 13.2-2.4 6.8 0 12.5 1.4 17.1 4.1a27 27 0 0 1 10.8 13.3l5.5-4.2a30.6 30.6 0 0 0-12.6-14.5A39.9 39.9 0 0 0 555.1-1c-6.5 0-12.1 1.1-16.9 3.4a27.3 27.3 0 0 0-10.9 9 20.8 20.8 0 0 0-3.8 12.3 18.2 18.2 0 0 0 9.8 17c3.4 2 7.9 3.6 13.5 4.9l17.9 4a28 28 0 0 1 13.8 5.8c2.6 2.6 3.9 5.7 3.9 9.4s-1.1 7.1-3.2 9.8a21 21 0 0 1-9 6 39 39 0 0 1-13.2 2c-5.2 0-9.9-.7-14.1-2.2a26.4 26.4 0 0 1-16.7-17.3l-5.8 4.5c1.6 4.4 4 8.2 7.4 11.3 3.3 3.1 7.5 5.5 12.3 7.3a50.5 50.5 0 0 0 33.7-.4 27 27 0 0 0 11.4-8.6c2.8-3.7 4.2-7.9 4.2-12.7 0-5.5-1.7-10-5.2-13.5a37.7 37.7 0 0 0-18.3-7.9zM606.3 81.3V46.2h41.8V40h-41.8V6.4h52.9V.3h-59.5v87.1h61.6v-6.1z"
                        />
                      </motion.svg>
                    )}
                  </AnimatePresence>
                </Link>
              </motion.div>
              <NavigationHeaderLinkList
                links={links}
                secondaryLinks={secondaryLinks}
                isNavOpen={isNavOpen}
                setIsNavOpen={setIsNavOpen}
                isMobile={isMobile}
                styleOverrides={{
                  links: `${theme.text === '#ffffff' && !isNavOpen ? 'text-white' : 'text-xumoBlack'}`,
                }}
              />
            </div>
          </div>
        </div>
      </motion.header>
      <div className="h-[100px]" />
      <div id="after-nav" />
    </>
  );
};
