import { useContext } from 'react';
import * as yup from 'yup';
import { InputType, isEnterpriseOrShopper, PageContext, useSanitizedTranslation } from '..';
import { XumoForm } from './Form';
import { withModal } from './hocs';
export type SignUpPopupProps = {
  trigger: string;
  heading: string;
  buttonLabel: string;
  placeholder: string;
};

export const PeacockPromo = withModal(({ heading, placeholder, buttonLabel, handleClose } : { handleClose }) => {
  const { t } = useSanitizedTranslation();
  const { pageCtx } = useContext(PageContext);
  const { host } = pageCtx;

  const placeholderText = placeholder.split(' ').reduce((acc, curr, i, arr) => {
    if (i === Math.floor(arr.length / 2)) {
      return acc + '\r\n' + curr;
    }
    return acc + ' ' + curr;
  });

  return (
    <div className={'flex w-full min-w-[350px] flex-col p-6 bg-white'} data-test-id="sign-up-popup">
      <h3 className={`type-heading mb-6 mt-4 text-center`} data-i18n={heading} dangerouslySetInnerHTML={{ __html: t('Get Peacock') }} />
      
    </div>
  );
});
