// import React from 'react';
// import { useTranslation } from 'next-i18next';

// export type DisclaimerProps = {
//   disclaimer: string;
//   top?: number;
//   align?: 'center' | 'left' | 'right';
//   offset?: number;
// };

// const Disclaimer: React.FC<DisclaimerProps> = ({ disclaimer, top = 0, align = 'center', offset = 0 }) => {
//   const { t } = useTranslation('common');

//   return (
//     <div className="lg:wrapper relative max-lg:w-full" style={{ top, zIndex: 100, textAlign: align }}>
//       <div
//         style={{ paddingLeft: align === 'left' ? offset : 0, paddingRight: align === 'right' ? offset : 0 }}
//         data-i18n={disclaimer}
//         dangerouslySetInnerHTML={{ __html: t(disclaimer) }}
//       />
//     </div>
//   );
// };

// export default Disclaimer;

import React from 'react';
import { useSanitizedTranslation } from '../hooks';

export type DisclaimerProps = {
  disclaimer: string;
  top?: number;
  align?: 'center' | 'left' | 'right';
  offset?: number;
};

const Disclaimer: React.FC<DisclaimerProps> = ({ disclaimer, top = 0, align = 'center', offset = 0 }) => {
  const { t } = useSanitizedTranslation();

  return (
    <div className="-mb-16 w-full bg-xumoSmoke pb-8 pt-24" style={{ top, zIndex: 20, textAlign: align }}>
      <div
        className="wrapper type-body relative text-sm"
        style={{ paddingLeft: align === 'left' ? offset : 0, paddingRight: align === 'right' ? offset : 0 }}
        data-i18n={disclaimer}
        dangerouslySetInnerHTML={{ __html: t(disclaimer) }}
      />
    </div>
  );
};

export default Disclaimer;
