/* eslint-disable sonarjs/no-duplicate-string */
import type { AppProps } from 'next/app';
import '../styles/global.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { appWithTranslation } from 'next-i18next';
import { PageProvider, SkipLink, CookieConsentFooter, NavigationHeader, AdobeAnalytics } from 'ui';
import { useRouter } from 'next/router';
import React, { useMemo, useEffect } from 'react';

function App({ Component, pageProps }: AppProps) {
  const {
    query: { slug },
  } = useRouter();

  const {
    metadata,
    locale
  } = pageProps

  const route = slug ? slug.join('/') : '/';
  const path = slug 
    ?  '/' + (slug as string[]).join('/')
    : '/'

  // Prevent page from re-rendering on hash change
  const component = useMemo(() => {
    return <Component {...pageProps} />;
  }, [pageProps, Component]);

  return (
    <PageProvider>
      <AdobeAnalytics path={metadata.host + path} locale={locale} title={metadata.title} />
     {route !== 'advertising' && <NavigationHeader />}
      <SkipLink className={'skip-link absolute top-32 z-10 hover:cursor-pointer focus:outline-none'} skipTo={'#after-nav'}>
        <React.Fragment />
      </SkipLink>
      {component}
      <CookieConsentFooter />
    </PageProvider>
  );
}

export default appWithTranslation(App);
