/* eslint-disable react/no-unescaped-entities */
import React, { useMemo, useState, useRef, useEffect, memo } from 'react';
import { AnimatePresence, motion, useSpring, useTransform } from 'framer-motion';
import { CTA, IImage } from '../types';
import { MotionImage } from './AnimatedImage';
import { Button } from '.';
import { makeVariants } from '../utils/makeVariants';
import { useScrolledTheme, useInViewScroll, SplitH2, useSanitizedTranslation } from 'ui';
import Head from 'next/head';
import { BazaarVoiceBlock } from './BazaarVoiceBlock';
import Image from 'next/image';
import Script from 'next/script';
import Link from 'next/link';
import devicesJSON from 'ui/devices.json'

export type ProductListingBlockProps = {
  deviceId: string;
};

const DeviceItem: React.FC<{
  strap: string;
  heading: string;
  body: string;
  sizes: any;
  features: any;
  images: IImage[];
  buttons: any[];
  productId: string;
}> = ({ strap, buttons, heading, body, sizes, features, images, logo, padLogo, moreInfo, flex, comingSoon, productId, redirectUrl }) => {

  const [ showInfo, setShowInfo ] = useState(false)

  const getGalleryJsonLd = () => {
    const obj = {
      '@context': 'https://schema.org',
      '@type': 'Product',
      '@id': 'https://www.xumo.com/products/xumo-tv',
      brand: 'Xumo',
      name: heading,
      description: body,
      image: images.src,
    };

    return {
      __html: JSON.stringify(obj),
    };
  };

  const variants = makeVariants.slideIn();

  return (
    <>
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={getGalleryJsonLd()} />
      </Head>
      <div className={`bg-white text-black rounded-xl p-6 flex flex-col justify-between`}>
        <div className="flex flex-col space-x-4">
          <div className="relative mb-6 flex">
            <div className="relative w-full aspect-video">
              <MotionImage {...images} sizes="(max-width: 768px) 100vw, (max-width: 1920px) 50vw, 25vw" fill className="object-contain" />
            </div>
          </div>
          <div className="space-y-3 text-left basis-1/2">
            <div className="relative w-full"  style={{height: padLogo ? 20 : 30, marginBottom: padLogo ? 15 : 5}}>
              <MotionImage src={logo} sizes="(max-width: 768px) 100vw, (max-width: 1920px) 50vw, 25vw" fill className="object-left object-contain" />
            </div>
            <div className="type-subheading">
              {heading}
            </div>
            <div
              data-bv-show="inline_rating"
              data-bv-product-id={productId}
              data-bv-redirect-url={redirectUrl}>
            </div>
            <div> <Image src="/static/icons/icon-tv.svg" width={20} height={20} className="h-6 w-6 mr-2 inline-block" /> {sizes.map(size => size + '"').join(' | ')}</div>
          </div>
        </div>
        <div className="mt-6 flex flex-col justify-center items-center space-x-0 space-y-3">
          {buttons.map(button => (
            <ShopButton {...button} />
          ))}

          <Link href={redirectUrl} className="border-none text-xumoBlack outline-none mt-6 flex flex-col justify-center space-x-0 space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0 text-center">
            View product details
          </Link>
        </div>
      </div>
    </>
  );
};


// TODO: add more props
//const ShopButton: React.FC<{ image: IImage }> = ({ image }) => {
const ShopButton = ({ image, url, name, label, bvValue }) => {

  return (
    <div className="inline-block">
      <Button
        label={label}
        href={url}
        disabled={!url}
        buttonType={!url ? "submit-primary" : "link-primary"}
        onClick={evt =>{
          evt.preventDefault();
          BV.pixel.trackConversion({
            type: 'Shop now',
            label: name,
            value: bvValue,
          });
          window.open(evt.currentTarget.href, '_blank');
        }}
        styleOverrides={{
          tailwind: 'inline-block px-6 bg-xumoBerry disabled:bg-xumoSmoke cursor-pointer disabled:cursor-default disabled:text-xumoGrey disabled:drop-shadow-none',
          css: {
            backgroundColor: '',
            color: 'white',
          },
        }}
      />
    </div>
  );
};

const Dropdown = ({options, filters, label, type, setFilters}) => {
  const [ shown, setShown ] = useState(false)
  const onClick = evt =>{
    setShown(shown => !shown)
  }

  const ref = useRef()
  const dropdownRef = useRef()

  useEffect(() => {
    // Function to handle click event
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target) && !dropdownRef.current.contains(event.target)) {
        setShown(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    
    // Clean up the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const toggleFilter = (option, value) => {
    setFilters(filters => {
      const current = filters[type]
      current[option] = value

      const newFilters = {
        ...filters,
        [type]: current
      }

      return newFilters
    })
  }

  return <div className="relative z-20 flex">
      <span ref={ref}>
        <Button
          label={label}
          buttonType={"submit-primary"}
          onClick={onClick}
          styleOverrides={{
            tailwind: 'z-10 inline-block bg-xumoWhite text-xumoOnyx hover:border-xumoBerry border-xumoOnyx border disabled:bg-xumoSmoke cursor-pointer disabled:cursor-default disabled:text-xumoGrey disabled:drop-shadow-none min-w-[160px] lg:min-w-[200px] text-left items-start sm:px-2 lg:px-6',
          }}
        />
      </span>
      <motion.div
        ref={dropdownRef}
        aria-hidden={!shown} 
        style={{pointerEvents: shown ? 'auto' : 'none'}}
        className="absolute border border-xumoOnyx bg-xumoWhite -bottom-2 left-0 rounded-xl p-2 drop-shadow-md space-y-1 transitions-colors"
        initial={{ opacity: 0, y: '95%'}}
        animate={{ opacity: shown ? 1 : 0, y: shown ? '100%' : '95%' }}
        transition={{duration: 0.1}}>
        {options.map(option => {
          const isActive = filters[type][option]

          return <button className="min-w-[175px] hover:bg-xumoSmoke rounded-xl p-2 justify-between flex" onClick={() => toggleFilter(option, !isActive)}>
            <span>{option}</span>
            <span className={`w-[20px] h-[20px] rounded-md ${isActive ? 'border-xumoBerry bg-xumoBerry' : 'border-xumoOnyx bg-white'} border`} />
          </button>
        })}
      </motion.div>
    </div>
}

const Contents = memo(() => {
  const { t } = useSanitizedTranslation()
  const [ filters, setFilters ] = useState({size: {}, brand: {}}) 

  const devices = devicesJSON
    .filter(device => {
      if (Object.values(filters.size).every(value => !value)) return true

      const intersects = device.sizes.some(element => {
        const values = Object.entries(filters.size)
          .filter(entry => entry[1])
          .map(entry => Number.parseInt(entry[0]))

        return values.includes(element) 
      })

      if (intersects) return true

      return false
    })
    .filter(device => {
      if (Object.values(filters.brand).every(value => !value)) return true
      const activeBrands = Object.entries(filters.brand)
        .filter(entry => entry[1])
        .map(entry => entry[0])
      const intersects = activeBrands.includes(device.brand)
      if (intersects) return true
      return false
    })

  return <div className="text-left">
    <h1 className="type-heading text-3xl mb-8" data-i18n>{t('Find your TV')}</h1>
    <div className="flex justify-between flex-col md:flex-rowitems-center mb-4 relative z-10">
      <div className="flex space-x-2">
        <Dropdown setFilters={setFilters} filters={filters} type="size" label="TVs by Size" options={[86, 75, 70, 65, 55, 50, 43, 40, 32, 24]} />
        <Dropdown setFilters={setFilters} filters={filters} type="brand" label="TVs by Brand" options={['Element', 'Hisense', 'Pioneer']}/>
      </div>
      <button className="text-left py-4 inline-block" onClick={() => setFilters({size: {}, brand: {}})}>
        Clear filters
      </button>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 min-h-[300px] relative">
      {devices.map(device => <DeviceItem {...device} />)}
      {devices.length === 0 && <div className="absolute w-full type-body-bold text-center p-5">No devices that match these filters.</div>}
    </div>
  </div>
})

export const ProductListingBlock: React.FC<ProductListingBlockProps> = memo(({deviceId = 0}) => {
  const ref = useRef()
  const { componentInView, colors } = useScrolledTheme({
    ref,
    background: '#E0E7ED',
    accent: '#2AAC00',
    text: '#000000',
  });

  const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
  const url = isProduction 
    ? "https://apps.bazaarvoice.com/deployments/xumo/main_site/production/en_US/bv.js"
    : "https://apps.bazaarvoice.com/deployments/xumo/main_site/production/en_US/bv.js"

  return <>
    <Script 
      type="text/javascript"
      src={url}
      strategy="afterInteractive" />
    <motion.section ref={ref} className={'wrapper py-8'} data-test-id="product-listing-block" style={{color: colors.text}}>
      <Contents />
    </motion.section>
  </>;
});