/* eslint-disable max-len */
import { motion, AnimatePresence } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import { Button } from 'ui';
import { useRouter } from 'next/router';

export const VideoModal: React.FC<any> = ({ anchor, video, override }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  // TODO: convert to hook -> const anchorActive = useActiveAnchor(anchor)
  const router = useRouter();
  const anchorIsActive = anchor && new URL('https://a.a' + router.asPath).hash === '#' + anchor;

  useEffect(() => {
    const handleVideoPlayback = async () => {
      const videoElement = videoRef.current;
      if (anchorIsActive && videoElement) {
        const videoSrc = override || video.src;

        if (videoSrc.endsWith('.m3u8')) {
          
          const Hls = (await import('hls.js')).default;
          if (Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(videoSrc);
            hls.attachMedia(videoElement);
          } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
            videoElement.src = videoSrc;
          }
        } else {
          videoElement.src = videoSrc;
        }

        videoElement.play();
      }
    };

    handleVideoPlayback();
  }, [anchorIsActive, override, video.src]);

  return (
    <>
      <AnimatePresence>
        {anchorIsActive && (
          <motion.div
            initial={{ opacity: 0, scale: 1.1 }}
            animate={{ opacity: 1, scale: 1, transition: { restDelta: 0.001 } }}
            exit={{ opacity: 0, scale: 1.1 }}
            className="align-center fixed bottom-0 left-0 right-0 top-0 z-50 flex h-screen flex-col items-center justify-center overflow-hidden bg-[#000000ee]"
          >
            <div className="container relative flex aspect-video h-[50vh] max-h-[50vh] grow-0 flex-col justify-center lg:w-max">
              <video
                controls
                ref={videoRef}
                muted={override && override !== '/static/videos/streambox-optimized.mp4' && override !== '/static/videos/enterprise-opt.mp4' && (override.indexOf('.m3u8') < 0)}
                data-src={override || video.src}
                className="aspect-video h-full grow-0 object-contain"
              />
              <div>
                <Button
                  label={'Close video'}
                  href="#"
                  buttonType={'primary'}
                  styleOverrides={{
                    tailwind: 'm-4 px-4 py-2 m-2 text-black bg-white sm:inline-block',
                  }}
                />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
