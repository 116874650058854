import { createContext, useEffect, useState } from 'react';
import { Website } from '../types';
import Color from 'color';

type PageContextType = {
  pageCtx: { host: Website.Enterprise | Website.Corporate | Website.Shopper | ''; isCorporate: boolean };
  setPageCtx: (ctx: { host: Website.Enterprise | Website.Corporate | Website.Shopper | ''; isCorporate: boolean }) => void;
  setTheme: (theme: any) => void;
  theme: any;
};

export const PageContext = createContext<PageContextType>({ pageCtx: { host: '', isCorporate: false }, setPageCtx: ({}) => {}, theme: {}, setTheme: () => {} });

export const PageProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [pageCtx, setPageCtx] = useState<PageContextType['pageCtx']>({ host: Website.Shopper, isCorporate: false });
  const [theme, setTheme] = useState<any>({});

  useEffect(() => {
    document.body.style.background = Color(theme.background).hex();
  }, [theme]);

  return <PageContext.Provider value={{ pageCtx, setPageCtx, theme, setTheme }}>{children}</PageContext.Provider>;
};
