import { FC } from 'react';
import { DropDownBlock, Highlighted } from '.';
import { IImage } from '../types';
import Head from 'next/head';
import { useSanitizedTranslation } from '../hooks';

export type FaqBlockProps = {
  questions: Question[];
  heading: string;
  searchPlaceholder: string;
  backgroundColor: string;
  backgroundImage?: IImage;
};

export type Question = {
  heading: string;
  answer: string;
};

export const FaqBlock: FC<FaqBlockProps> = ({ questions, heading, searchPlaceholder, backgroundColor, backgroundImage }) => {
  const { t } = useSanitizedTranslation();

  const getQuestionJsonLd = (heading: string, answer: string) => {
    const obj = {
      '@context': 'https://schema.org',
      '@type': 'Question',
      name: heading,
      answerCount: 1,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answer,
      },
    };
    return {
      __html: JSON.stringify(obj),
    };
  };

  const AnswerItem = ({ answer, searchValue, heading }: { answer: string; searchValue: string; heading: string }) => (
    <div className={`xs:max-md:w-full type-body flex h-full w-full flex-col items-start text-left text-black md:flex-row md:pt-4`} data-i18n={answer}>
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={getQuestionJsonLd(heading, answer)} />
      </Head>
      <Highlighted text={t(answer)} highlight={searchValue} />
    </div>
  );

  return (
    <div className="w-full">
      <section
        id="faq"
        className="relative w-full"
        style={{
          backgroundColor,
        }}
      >
        <DropDownBlock
          items={questions}
          ItemComponent={AnswerItem}
          heading={heading}
          searchPlaceholder={searchPlaceholder}
          backgroundImage={backgroundImage}
          backgroundColor={backgroundColor}
        />
      </section>
      <div className="h-[74px] w-full bg-xumoSmoke"></div>
    </div>
  );
};
