import { useCallback } from 'react';
import { useTranslation as useT } from 'react-i18next';
import { sanitize } from 'isomorphic-dompurify';

export const useSanitizedTranslation = () => {
  const { t: translation } = useT('common');
  const t = useCallback(
    (key: string) => {
      const text = translation(key);
      return sanitize(text);
    },
    [translation],
  );

  return { t };
};
