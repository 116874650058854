import React from 'react';
import { MagnifyingGlass } from './';
import { useSanitizedTranslation } from '../hooks';

type SearchBarProps = {
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeHolder: string;
};

export const SearchBar = ({ value, handleChange, placeHolder }: SearchBarProps) => {
  const { t } = useSanitizedTranslation();

  return (
    <div className="relative flex w-full flex-row items-center justify-center">
      <input
        className="type-body mt-8 h-8 w-[64%] border border-solid border-black border-x-transparent border-t-transparent px-4 py-0  
     focus:border-b-xumoAegean focus:outline-none"
        type="text"
        id="search-bar"
        onChange={handleChange}
        value={value}
      />
      {value === '' && (
        <label
          htmlFor="search-bar"
          className={`pointer-events-none absolute left-[50%] top-[55%] mx-2 flex w-[64%] translate-x-[-50%] items-center 
          justify-center  whitespace-nowrap max-md:w-[50%]`}
        >
          <p className={`w-[330px] overflow-hidden text-ellipsis whitespace-nowrap text-center  text-sm `} data-i18n={placeHolder}>
            {t(placeHolder)}
          </p>
          <span className="max-sm:pl-1">
            <MagnifyingGlass />
          </span>
        </label>
      )}
    </div>
  );
};
