/* eslint-disable max-len */

import { Website } from '../types';
import { mapDirectusMetadata, mapDirectusPage, mapDirectusPress, MappingDirectusPage, MappingPageContent } from './mapping';

import { DirectusPage } from 'config';
import websiteContent from 'config/xumo-website-output.json';

type WebsiteOptions = Website.Corporate | Website.Enterprise | Website.Shopper;

export interface GetGeneralPageStaticPropsArgs {
  website: WebsiteOptions;
}

export type GetGeneralPageStaticPropsParams = {
  slug: string[] | undefined;
  website: WebsiteOptions;
};

export type GetGeneralPageMetadataResponse = {
  title: string;
  description: string;
  image: string;
  url: string;
  host: WebsiteOptions;
};

export type GetGeneralPageStaticPropsResponse = {
  locale: string;
  page: MappingDirectusPage;
  metadata: GetGeneralPageMetadataResponse;
  cssVariables?: string;
};

export type GetGeneralPageStaticPropsContext = {
  params: GetGeneralPageStaticPropsParams;
  locale: string;
};

export const getPageContent = (data: DirectusPage[], slug: string[] | null) => {
  return data.find((page: DirectusPage) => (slug === null ? page.slug === null : page.slug === slug.join('/')));
};

export const getGeneralPageStaticProps =
  ({ website }: GetGeneralPageStaticPropsArgs) =>
  async ({ params, locale }: GetGeneralPageStaticPropsContext): Promise<{ props: GetGeneralPageStaticPropsResponse }> => {
    const slug = params?.slug || null;
    const isPress = (slug?.length || 0) > 1 && (slug as string[])[0] === 'press';

    const data = getPageContent(isPress ? (websiteContent as any).press : (websiteContent as any).content, slug);

    const page = isPress
      ? mapDirectusPress(data as Omit<MappingPageContent, 'collection'>, (websiteContent as any).settings)
      : mapDirectusPage(data as MappingDirectusPage, (websiteContent as any).settings);

    const metadata = mapDirectusMetadata(data as MappingDirectusPage, isPress);

    return {
      props: {
        // hack to get around next.js complaining about undefined values
        page: JSON.parse(JSON.stringify(page)),
        metadata,
        locale: locale ?? 'en',
      },
    };
  };
