/* eslint-disable max-len */
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { Button, HeaderLink } from '.';
import { PageContext } from '../contexts';
import { useShowElement } from '../hooks/useShowElement';

import { motion } from 'framer-motion';
import { useSanitizedTranslation } from '../hooks';

type NavigationHeaderLinkItemProps = {
  index: number;
  isMobile: boolean;
  link: HeaderLink;
  styleOverrides?: string;
  isNavOpen: boolean;
  setIsNavOpen: (open: boolean) => void;
};

// type NavigationHeaderLinkItemState = NavigationHeaderLinkItemProps & {
//   handleHideSublinks: () => void;
//   handleShowSublinks: () => void;
//   isHovered: boolean;
//   pathName: string;
// };

const rewriteTable: Record<string, Record<string, string>> = {
  'tv.xumo.com': {
    staging: 'tv.stg.xumo.com',
    uat: 'tv.qa.xumo.com',
  },
};

const findMatch = (link: string): string | undefined => {
  const possibleRewrites = Object.keys(rewriteTable);
  for (let i = 0; i < possibleRewrites.length; i++) {
    const substring = possibleRewrites[i];
    if (link.includes(substring)) {
      return substring;
    }
  }
};

const checkRewrite = (link: string): string => {
  const { VERCEL_GIT_COMMIT_REF: branch } = process.env;
  const found = findMatch(link);
  const rewrite = found ? rewriteTable[found] && rewriteTable[found][branch as string] : false;
  return rewrite && found ? link.replace(found, rewrite) : link;
};

// const SubListItem = ({ handleHideSublinks, handleShowSublinks, isHovered, isMobile, styleOverrides, link, pathName }: NavigationHeaderLinkItemState) => {
//   const { t } = useTranslation('common');

//   const isCurrentPath = link.subLinks?.some(subLink => subLink.href === pathName);
//   const { pageCtx } = useContext(PageContext);
//   const { host } = pageCtx;

//   const xumoBerry = 'text-xumoBerry';

//   const { theme } = useContext(PageContext);

//   return (
//     <div
//       onMouseEnter={handleShowSublinks}
//       onClick={() => (isHovered ? handleHideSublinks() : handleShowSublinks())}
//       className={`${styleOverrides} ${isMobile ? 'whitespace-nowrap' : ''} group flex cursor-pointer items-center`}
//     >
//       <span
//         className={`${styleOverrides} type-body-bold no-shift flex items-center hover:text-xumoBerry ${isCurrentPath ? '' : 'type-body-bold hover:weight-600'}`}
//         data-i18n={link.label}
//         dangerouslySetInnerHTML={{ __html: t(link.label) }}
//         style={{ color: theme.text }}
//       />
//       {!!link.subLinks?.length && !isMobile && (
//         <ButtonIcon
//           icon={{
//             type: 'chevron-down',
//             color: `#555555`,
//             placement: 'right',
//             styleOverrides: `ml-1 mt-[2px]`,
//           }}
//         />
//       )}
//     </div>
//   );
// };

export const NavigationHeaderLinkItem = ({ index, isMobile, link, styleOverrides, isNavOpen, setIsNavOpen }: NavigationHeaderLinkItemProps) => {
  const { t } = useSanitizedTranslation();
  const pathName = useRouter().asPath;
  const { isVisible: isHovered, setIsVisible: setIsHovered, hideElement: handleHideSublinks, showElement: handleShowSublinks } = useShowElement(false);
  const { pageCtx, theme } = useContext(PageContext);
  const { host } = pageCtx;

  return (
    <div className={`${isMobile ? 'flex-col items-start' : 'h-8 items-center'} flex`} onMouseEnter={handleShowSublinks} onMouseLeave={handleHideSublinks}>
      <div className={`relative`}>
        <Link
          className={`nav-link ${styleOverrides} ${link.href === pathName ? 'weight-700 nav-active' : 'weight-500'} type-body-bold hover:weight-700 no-shift flex cursor-pointer flex-row items-center px-4 py-4 transition-colors max-lg:whitespace-nowrap lg:flex-col
          `}
          style={{ fontSize: '16px' }}
          href={checkRewrite(link.href as string)}
          passHref
          data-i18n={link.label}
          onClick={() => setIsNavOpen(false)}
        >
          <span data-i18n={link.label} className="inline-block">
            {t(link.label)}{' '}
            <span data-i18n className="ml-4 inline-block lg:hidden">
              {t(link.preview ? ' (Coming Soon)' : '→')}
            </span>
          </span>
        </Link>

        {/*<AnimatePresence>*/}
        {link.subnav && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: isHovered || isNavOpen ? 1.0 : 0.0,
              y: isHovered || isNavOpen ? 0 : -10.0,
            }}
            transition={{ duration: 0.2 }}
            style={{ pointerEvents: isHovered || isNavOpen ? 'auto' : 'none' }}
            className={`top-[calc(100%)] rounded-lg px-2 text-black lg:absolute lg:bg-white lg:py-4`}
          >
            <div className="flex flex-col md:flex-row md:space-x-10 lg:space-x-6">
              {link.subnav.sections?.map((section, id) => (
                <div key={'section-' + index + '-' + id} className="mx-2 flex min-w-[100px] flex-col space-y-1 whitespace-nowrap text-left max-lg:mb-4">
                  <div data-i18n className="weight-500 pb-2 text-sm uppercase text-xumoBerry transition-colors">
                    {t(section.title)}
                  </div>
                  {section.links.map((link, id) => (
                    <Link
                      key={index + id + link.label}
                      onClick={() => setIsNavOpen(false)}
                      data-i18n
                      className="block cursor-pointer text-black transition-all hover:opacity-50 max-md:pl-2"
                      href={checkRewrite(link.href || '')}
                    >
                      {t(link.label)}
                    </Link>
                  ))}
                </div>
              ))}
              {link.subnav.featured && (
                <div className="featured hidden cursor-pointer lg:flex">
                  <Link href={checkRewrite(link.subnav.featured.link.href)}>
                    <div
                      className="relative -top-2 w-[250px] whitespace-nowrap rounded-lg bg-xumoSmoke p-2 pb-0 text-left"
                      style={{ height: 'calc(100% + 1rem)' }}
                    >
                      <div data-i18n className="weight-500 pb-2 text-sm uppercase text-xumoBerry transition-colors">
                        {t(link.subnav.featured.title)}
                      </div>
                      <div className="aspect-video w-full overflow-hidden rounded-lg bg-xumoBerry relative">
                        <Image 
                          fill
                          src={link.subnav.featured.image.src} 
                          className="h-full w-full object-cover transition-all"
                          alt={link.subnav.featured.title} />
                      </div>

                      <div data-i18n className="label -mb-2 whitespace-normal pt-2 text-black transition-all">
                        {t(link.subnav.featured.link.label)}
                      </div>
                    </div>
                  </Link>
                </div>
              )}
            </div>
            {link.subnav.cta && (
              <div className="hidden flex-col items-center px-2 pr-2 pt-6 text-left lg:flex">
                <div className="pb-2">Looking to take your business to the next level?</div>
                <Button
                  label={'Contact Us'}
                  href="/enterprise/contact"
                  buttonType="link-primary"
                  styleOverrides={{
                    tailwind: 'ml-4 px-4 py-2 mr-2',
                    css: {
                      backgroundColor: theme.accent,
                      borderColor: theme.accent,
                      borderWidth: 2,
                      boxSizing: 'border-box',
                    },
                  }}
                />
              </div>
            )}
          </motion.div>
        )}
      </div>
      <style>{`
        .featured:hover img {
          transform: scale(1.05);
          opacity: 0.75;
        }
        .featured:hover .label {
          opacity: 0.5;
        }
      `}</style>
    </div>
  );
};
