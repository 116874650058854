/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
import { motion, useSpring, useTransform, AnimatePresence } from 'framer-motion';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useInViewScroll, useScrolledTheme, useWindowSize, useSanitizedTranslation } from '../hooks';
import { makeVariants } from '../utils/makeVariants';
import { SplitH1, Button, SplitH4 } from 'ui';
import Link from 'next/link';
import Image from 'next/image';

import { CTA, Colors, IImage } from '../types';
import { useRouter } from 'next/router';

export type VideoHeroProps = {
  heading: string;
  body?: string;
  ctas: CTA[];
  image: IImage;
};

const squiggleVariant = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 80,
      damping: 35,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.055,
      type: 'spring',
      stiffness: 90,
      damping: 23,
    },
  },
};

const colorFallback = {
  '/shop': '#396059',
  '/shop/xumo-tv': '#0029FF',
  '/shop/xumo-stream-box': '#0029FF',
  '/shop/streaming': '#0029FF',
};

const copyVariants = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      when: 'afterChildren',
      delayChildren: 0,
      staggerChildren: 0.01,
      type: 'spring',
      stiffness: 400,
      damping: 100,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      delayChildren: 0,
      staggerChildren: 0.025,
      type: 'spring',
      stiffness: 80,
      damping: 23,
    },
  },
};

const DealsBanner = () => {
  const { t } = useSanitizedTranslation();

  return <motion.div 
    initial={{opacity: 0, scale: 0.8}} 
    animate={{opacity: 1, scale: 1}} 
    transition={{ delay: 1.2, duration: 0.8, ease: 'easeOut' }}
    style={{ boxShadow: '0 0 15px rgba(255, 0, 255, 0.5), 0 0 2px rgba(255,0,255,0.6)' }} 
    className="bg-[#00000044] md:w-1/2 lg:w-1/3 border-2 border-xumoBerry p-3 mb-12 rounded-lg inline-block border-bottom">
    <div>
    <span data-i18n className="text-body text-sm weight-500 mr-2 inline-block">{t('Get 6 months of Peacock Premium at no extra cost — just in time for The Olympics.')}</span>

      <Link href="#peacock-offer">
        <span className="text-body text-sm underline text-white hover:no-underline underline-offset-[5px]">{t("Discover more")}&nbsp;&rsaquo;</span>
      </Link>
    </div>
  </motion.div>
}


export const VideoHero: React.FC<VideoHeroProps> = ({ heading, body, image, ctas }) => {
  const { width } = useWindowSize();

  const isMobile = width <= 767;

  const { ref, progress: y } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
  const amt = useSpring(y, { stiffness: 400, damping: 90 });
  const containerY = useTransform(amt, [0, 1], ['0', '-15vh']);
  const opacity = useTransform(amt, [0, 0.4, 0.6, 1], [1, 1, 0.85, 0.05]);

  const router = useRouter();
  const fallbackColor = colorFallback[router.asPath as keyof typeof colorFallback];
  const variants = makeVariants.slideIn();

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [videoState, setVideoState]  = useState<HTMLVideoElement | null>(null)

  const [isPaused, setIsPaused] = useState(false);

  const handleVideo = useCallback(() => {
    setIsPaused(!isPaused);
    if (videoState) {
      if (!isPaused) {
        videoState.pause();
      } else {
        videoState.play();
      }
    }
  }, [isPaused, videoState]);

  const { componentInView, colors } = useScrolledTheme({
    ref,
    background: '#000000',
    accent: fallbackColor || '#2AAC00',
    text: '#ffffff',
  });


  useEffect(() => {
    const initVideo = async () => {
      const video = videoState;
    
      if (video) {
        const videoSrc = video.getAttribute('data-src');
        if (videoSrc && videoSrc.endsWith('.m3u8')) {
          const Hls = (await import('hls.js')).default;
          if (Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(videoSrc);
            hls.attachMedia(video);
            videoState.currentTime = 4.0;
            videoState.play();
          } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = videoSrc;
          }
        } else {
          video.src = videoSrc;
        }
      }
    }

    initVideo();
  }, [videoState]);

  return (
    <div className="relative w-full">
      {!isMobile ? (
        <button className={`absolute right-[32px] top-[45%] z-10 cursor-pointer`} onClick={handleVideo}>
          {!isPaused ? (
            <Image alt="pause" src="/pause.png" width={40} height={40} />
          ) : (
            <Image style={{ opacity: 0.4 }} alt="image" src="/play-circle.png" width={40} height={40} />
          )}
        </button>
      ) : null}
      
        <div className="fixed bottom-0 left-0 right-0 top-0 h-[115vh] overflow-hidden pointer-events-none">
          <motion.div style={{ y: containerY, opacity }} className="relative h-full w-full">
            <AnimatePresence>
              {componentInView && (
                <motion.div initial="hidden" animate="show" exit="hidden" variants={squiggleVariant} key="video-hero" className="absolute h-full w-full">
                  <div className="absolute z-10 h-full w-full" style={{ background: 'url(/static/images/gradient-bg.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', opacity: 0.7 }} />
                  <div className="left-1/2 2xl:left-[40%] absolute w-1/2 h-full flex items-center justify-center">
                    <div className="md:min-w-[850px] xl:min-w-[1050px] 2xl:min-w-[1200px] top-16 relative">
                      <div className="md:w-[525px] xl:w-[625px] 2xl:w-[800px] mx-auto relative z-20 -bottom-[80px]" style={{aspectRatio: 1.56875}}>
                        <Image 
                          src="/static/images/tv-panorama.png" 
                          fill 
                          objectFit="fill"
                          style={{filter: 'brightness(0.6)'}}
                          className="w-full h-full object-fill" />
                        <div style={{
                            transform: 'perspective(500px) rotateX(1deg)',
                            outline: '1px solid transparent',
                            position: "absolute",
                            top: "0.5%",
                            left: "0.9%",
                            right: "1%",
                            bottom: "13%",
                            zIndex: 10,
                            background: "black",
                            overflow: 'hidden',
                            willChange: 'transform',
                            backfaceVisibility: 'hidden'
                          }}>
                            <motion.div
                              className="absolute h-full w-full">
                              <video
                                poster='/static/assets/xumo-cover-img.jpg'
                                style={{outline: '1px solid rgba(0,0,0,0.05'}}
                                ref={setVideoState}
                                muted
                                loop
                                playsInline
                                data-src="https://cdn.muse.ai/u/SsDSBxC/7ddda3795e9761ecb51dff72a8324bf2142c50083652b7f1acb08a5d175858e4/videos/hls.m3u8"
                                className="relative h-full w-full object-cover object-top"
                              />
                            </motion.div>
                        </div>
                      </div>
                      <div className="max-md:hidden relative md:min-w-[850px] xl:w-[1050px] 2xl:min-w-[1200px] min-h-[300px] xl:min-h-[350px]" style={{filter: 'brightness(0.9)'}}>
                        <Image src="/static/images/tv-stand-purple.png" fill objectFit="contain" />
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </div>
      
      <section
        ref={ref}
        className={`relative top-0 flex w-full flex-col max-md:mt-[80px] md:top-[80px] md:flex-row lg:top-0`}
        data-id={'video-hero'}
        style={{ minHeight: !isMobile ? 520 : '100%' }}
      >
{/*        {isMobile ? (
          <Image
            className="h-full w-full"
            style={{
              objectFit: 'cover',
            }}
            alt="hero"
            src={image.src}
            fill
          />
        ) : null}*/}
        <motion.div
          variants={variants}
          initial="initial"
          whileInView={'animate'}
          viewport={{ once: true }}
          className={`wrapper relative z-[2] flex h-full flex-col`}
          style={{ color: Colors.Black, opacity: 0 }}
        >
          <div className="w-full text-left">
            <motion.div
              initial="hidden"
              animate="show"
              variants={variants}
              className="wrapper relative flex w-full flex-col justify-center text-left text-white max-lg:pb-[50px] max-lg:pt-[100px] lg:h-[calc(100vh-100px)] lg:min-h-[500px]"
            >
              <div className="w-full max-md:text-center">
                <SplitH1 content={heading} variants={copyVariants} className={`text-center md:text-left type-title w-full text-[${isMobile ? '40px' : '56px'}]`} />
                {body ? (
                  <div className="flex flex-row pt-[24px]">
                    <SplitH4 content={body} variants={copyVariants} className={`type-body-bold text-center md:text-left w-full text-[${isMobile ? '16px' : '24px'}]`} />
                  </div>
                ) : null}
                <motion.div
                  style={{ paddingTop: isMobile ? '33px' : '48px', flexDirection: isMobile ? 'column-reverse' : 'row' }}
                  variants={copyVariants}
                  className={`flex items-center`}
                >
                  <Button
                    styleOverrides={{
                      css: { backgroundColor: ctas[0].backgroundColor },
                      tailwind: `w-[166px] h-[56px] md:mr-4`,
                    }}
                    href="#shop"
                    label={ctas[0].label}
                    buttonType="link-primary"
                  />
                  <Link href="#reel" legacyBehavior passHref>
                    <motion.a
                      whileHover="grow"
                      className={`inline-flex flex-row items-center`}
                      style={{ marginLeft: isMobile ? '0px' : '24px', marginBottom: isMobile ? '48px' : '0px' }}
                    >
                      <motion.div variants={{ grow: { scale: 1.1 } }} className="relative z-10 h-[40px] w-[40px]">
                        <Image alt="Play button" src="/static/icons/play_glow.svg" width={40} height={40} />
                      </motion.div>
                      <motion.span variants={{ grow: { opacity: 0.7 } }} className="weight-600 ml-2 text-left text-white">
                        Watch the full<br/>Xumo TV reel
                      </motion.span>
                    </motion.a>
                  </Link>
                </motion.div>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </section>
    </div>
  );
};
